
  body {
    background: black; /* Основний фон */
    overflow: hidden;
  }

  .grain-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1); /* Напівпрозорий шар */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100vh"><filter id="grain"><feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="2" stitchTiles="stitch"></feTurbulence><feComposite operator="in" in2="SourceGraphic"></feComposite></filter><rect width="100%" height="100%" filter="url(#grain)"/></svg>');
    animation: grain 3s steps(1) infinite;
  }

  @keyframes grain {
    0% {
      transform: translate(0, 0);
    }
    100% {
      /* transform: translate(-200px, -200px); Рух зернистості */
    }
  }



